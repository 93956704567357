import { default as _400kqG6ICY4ucMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/400.vue?macro=true";
import { default as _500qYeIOvgzhyMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/500.vue?macro=true";
import { default as contato4iFQJrA4cTMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/contato.vue?macro=true";
import { default as casas_45bahiaT5AkFgGCByMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue?macro=true";
import { default as digioWBvrZKFprzMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/credor/digio.vue?macro=true";
import { default as pontofrionmLHd9umODMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/credor/pontofrio.vue?macro=true";
import { default as empresaOYquEgnXPaMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/empresa.vue?macro=true";
import { default as indexYbZVc6QGARMeta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/index.vue?macro=true";
import { default as infov05MJlh4v4Meta } from "/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/info.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/400.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "credor-casas-bahia",
    path: "/credor/casas-bahia",
    meta: casas_45bahiaT5AkFgGCByMeta || {},
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: "credor-digio",
    path: "/credor/digio",
    meta: digioWBvrZKFprzMeta || {},
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: "credor-pontofrio",
    path: "/credor/pontofrio",
    meta: pontofrionmLHd9umODMeta || {},
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/credor/pontofrio.vue").then(m => m.default || m)
  },
  {
    name: "empresa",
    path: "/empresa",
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexYbZVc6QGARMeta || {},
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/codebuild/output/src3310326106/src/melembre-landing-page-v2/pages/info.vue").then(m => m.default || m)
  }
]